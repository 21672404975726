// import ReactSwipe from "react-swipe";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {A11y, Autoplay, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

import BanerVortex from "../assets/images/carousel_tiny/BanerVortexZiel.png";
import FineTest from "../assets/images/carousel_tiny/FineTest.png";
import FineTestMobilny from "../assets/images/carousel_tiny/mobile/Fine-TestMobilny.png";
import {Link} from "react-router-dom";
import TargetMol1Img from "../assets/images/carousel_tiny/TargetMol.png";
import TargetMol1ImgMobile from "../assets/images/carousel_tiny/mobile/TargetMol1.png";
import VortexMobilny from "../assets/images/carousel_tiny/mobile/VortexMobilnyZiel.png";
import classes from "./Carousel.module.css";
import cyanagenImgMobile from "../assets/images/carousel_tiny/mobile/Cyanagen.png";
import cyanagenStac from "../assets/images/carousel_tiny/CyanagenStac.png";
import invitekImg from "../assets/images/carousel_tiny/invitek.png";
import invitekImgMobile from "../assets/images/carousel_tiny/mobile/invitek.png";
import novelJuice from "../assets/images/carousel_tiny/NovelJuice.webp";
import novelJuiceMob from "../assets/images/carousel_tiny/mobile/noveljuicemob.png";
// import starlabImg from "../assets/images/carousel_tiny/Starlabkonc.png";
import starlabImgMobile from "../assets/images/carousel_tiny/mobile/starlabmob.png";
import starlabPdf from "../../download/Starlab_Ulotka.pdf";
import starlabgreen from "../assets/images/carousel_tiny/Starlab41green.webp";
import targetmolpdf from "../../download/targetmol_compounds.pdf";
import invitekpdf from "../../download/InvitekDiagnosticsCatalog.pdf";
import westarhipernova from "../../download/WESTAR_HYPERNOVA.pdf";
import zellx from "../assets/images/carousel_tiny/mobile/ZELLXmobile.png";
import zellxdesktop from "../assets/images/carousel_tiny/ZELLX.png";
import Proteintech from "../assets/images/carousel_tiny/Proteintech.png";

// import TargetMol2Img from "../assets/images/carousel_tiny/TargetMol2.jpg";
// import TargetMol2ImgMobile from "../assets/images/carousel_tiny/mobile/TargetMol2.png";
// import TargetMol3Img from "../assets/images/carousel_tiny/TargetMol3.jpg";
// import TargetMol3ImgMobile from "../assets/images/carousel_tiny/mobile/TargetMol3.png";
// import abcamImg from "../assets/images/carousel_tiny/abcam.jpg";
// import abcamImgMobile from "../assets/images/carousel_tiny/mobile/abcam.png";
// import antibodiesImg from "../assets/images/carousel_tiny/antibodies.jpg";
// import antibodiesImgMobile from "../assets/images/carousel_tiny/mobile/antibodies.png";

// import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
// import YoutubeEmbed from "../YoutubeEmbed";
// let reactSwipeEl;

// Import Swiper React components

// Import Swiper styles

// import required modules

const Carousel = () => {
    return (<section className={classes.carousel__wrapper}>
        <div className={classes.wrapper__fix}>
            <div className={classes.carousel__container}>
                <Swiper
                    navigation={true}
                    autoplay={{delay: 5000}}
                    pagination={{
                        clickable: true,
                        bulletClass: `${classes.bullets} swiper-pagination-bullet`,
                        bulletActiveClass: `${classes["bullets--active"]} swiper-pagination-bullet-active`,
                    }}
                    modules={[Navigation, Pagination, A11y, Autoplay]}
                    className={classes.mySwiper}
                    spaceBetween={50}
                >
                    <SwiperSlide className={classes.mobile_hidden_temp}>
                        <div className={classes.swiperInnerDiv}>
                            <img src={Proteintech} alt="Proteintech promotion informations" className={classes.carousel__image} />
                        </div>
                    </SwiperSlide>
                    {/*<SwiperSlide>
                        <div className={classes.swiperInnerDiv}>
                            <img src={zellxdesktop} alt="Zellx promotion informations"
                                 className={classes.carousel__image}/>
                        </div>
                        <div className={classes.swiperInnerDivMobile}>
                            <img src={zellx} alt="Zellx promotion informations"
                                 className={classes.carousel__image}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={classes.swiperInnerDiv}>
                            <img src={starlabgreen} alt="Starlab promotion informations"
                                 className={classes.carousel__image}/>
                        </div>
                        <div className={classes.swiperInnerDivMobile}>
                            <img src={starlabImgMobile} alt="Starlab promotion informations"
                                 className={classes.carousel__image}/>
                        </div>
                    </SwiperSlide>*/}
                    <SwiperSlide>
                        <div className={classes.swiperInnerDiv}>
                            <img src={novelJuice} alt="NovelJuice promotion informations"
                                 className={classes.carousel__image}/>
                        </div>
                        <div className={classes.swiperInnerDivMobile}>
                            <img src={novelJuiceMob} alt="NovelJuice promotion informations"
                                 className={classes.carousel__image}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={classes.swiperInnerDiv}>
                            <img src={BanerVortex} alt="Starlab promotion informations"
                                 className={classes.carousel__image}/>
                            <a className={classes["clicable__button--s1_whole"]} href={starlabPdf} target="_blank"
                               rel="noreferrer">
                                <p className={classes.sr__only}>Zobacz promocje Starlab</p>
                            </a>
                        </div>
                        <div className={classes.swiperInnerDivMobile}>
                            <img src={VortexMobilny} alt="Starlab promotion informations"
                                 className={classes.carousel__image}/>
                            <a
                                className={classes["clicable__button--s1_mobile_whole"]}
                                href={starlabPdf}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p className={classes.sr__only}>Zobacz promocje Starlab</p>
                            </a>
                        </div>
                    </SwiperSlide>
                  {/*  <SwiperSlide>
                        <div className={classes.swiperInnerDiv}>
                            <img src={FineTest} alt="Starlab promotion informations"
                                 className={classes.carousel__image}/>
                            <Link className={classes["clicable__button--s1_notmain"]} to="/Kontakt">
                                <p className={classes.sr__only}>Skontaktuj się z nami!</p>
                            </Link>
                        </div>
                        <div className={classes.swiperInnerDivMobile}>
                            <img src={FineTestMobilny} alt="Starlab promotion informations"
                                 className={classes.carousel__image}/>
                            <Link className={classes["clicable__button--s1_mobile_notmain"]} to="/Kontakt">
                                <p className={classes.sr__only}>Skontaktuj się z nami!</p>
                            </Link>
                        </div>
                    </SwiperSlide>*/}
                    {/* <SwiperSlide>
              <div className={classes.swiperInnerDiv}>
                <img src={starlabImg} alt="Starlab promotion informations" className={classes.carousel__image} />
              </div>
              <div className={classes.swiperInnerDivMobile}>
                <img src={starlabImgMobile} alt="Starlab promotion informations" className={classes.carousel__image} />
              </div>
            </SwiperSlide> 
            <SwiperSlide>
              <div className={classes.swiperInnerDiv}>
                <img src={abcamImg} alt="Abcam promotion informations" className={classes.carousel__image} />
              </div>
              <div className={classes.swiperInnerDivMobile}>
                <img src={abcamImgMobile} alt="Abcam promotion informations" className={classes.carousel__image} />
              </div>
            </SwiperSlide> */}
                    {/* <SwiperSlide>
              <div className={classes.swiperInnerDiv}>
                <img src={antibodiesImg} alt="Abcam promotion informations" className={classes.carousel__image} />
              </div>{" "}
              <div className={classes.swiperInnerDivMobile}>
                <img src={antibodiesImgMobile} alt="Abcam promotion informations" className={classes.carousel__image} />
              </div>
            </SwiperSlide> */}
                    <SwiperSlide>
                        <div className={classes.swiperInnerDiv}>
                            <img src={cyanagenStac} alt="Cyanagen promotion informations"
                                 className={classes.carousel__image}/>
                            <a className={classes["clicable__button--s1_whole"]} href={westarhipernova}
                               target="_blank"
                               rel="noreferrer">
                                <p className={classes.sr__only}>Zobacz promocje Cyanagen</p>
                            </a>
                        </div>
                        <div className={classes.swiperInnerDivMobile}>
                            <img
                                src={cyanagenImgMobile}
                                alt="Cyanagen promotion informations"
                                className={classes.carousel__image}
                            />
                            <a
                                className={classes["clicable__button--s1_mobile_whole"]}
                                href={westarhipernova}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p className={classes.sr__only}>Zobacz promocje Cyanagen</p>
                            </a>
                        </div>
                    </SwiperSlide>
                    {/*<SwiperSlide>*/}
                    {/*    <div className={classes.swiperInnerDiv}>*/}
                    {/*        <img src={starlabImg} alt="Starlab promotion informations"*/}
                    {/*             className={classes.carousel__image}/>*/}
                    {/*        <Link className={classes["clicable__button--s1_whole"]} to="/Kontakt">*/}
                    {/*            <p className={classes.sr__only}>Skontaktuj się z nami!</p>*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*    <div className={classes.swiperInnerDivMobile}>*/}
                    {/*        <img src={starlabImgMobile} alt="Starlab promotion informations"*/}
                    {/*             className={classes.carousel__image}/>*/}
                    {/*        <Link className={classes["clicable__button--s1_mobile_whole"]} to="/Kontakt">*/}
                    {/*            <p className={classes.sr__only}>Skontaktuj się z nami!</p>*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide>
                        <div className={classes.swiperInnerDiv}>
                            <img src={invitekImg} alt="Invitek promotion informations"
                                 className={classes.carousel__image}/>
                            <a className={classes["clicable__button--s1_whole"]} href={invitekpdf}
                               target="_blank"
                               rel="noreferrer">
                                <p className={classes.sr__only}>Zobacz promocje Invitek</p>
                            </a>
                        </div>
                        <div className={classes.swiperInnerDivMobile}>
                            <img src={invitekImgMobile} alt="Invitek promotion informations"
                                 className={classes.carousel__image}/>
                            <a
                                className={classes["clicable__button--s1_mobile_whole"]}
                                href={invitekpdf}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p className={classes.sr__only}>Zobacz promocje Invitek</p>
                            </a>
                        </div>
                    </SwiperSlide>*/}
                    <SwiperSlide>
                        <div className={classes.swiperInnerDiv}>
                            <img src={TargetMol1Img} alt="Targetmol promotion informations"
                                 className={classes.carousel__image}/>
                            <a className={classes["clicable__button--s1_whole"]} href={targetmolpdf}
                               target="_blank"
                               rel="noreferrer">
                                <p className={classes.sr__only}>Zobacz promocje Targetmol</p>
                            </a>
                        </div>
                        <div className={classes.swiperInnerDivMobile}>
                            <img src={TargetMol1ImgMobile} alt="Targetmol promotion informations"
                                 className={classes.carousel__image}/>
                            <a
                                className={classes["clicable__button--s1_mobile_whole"]}
                                href={targetmolpdf}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p className={classes.sr__only}>Zobacz promocje Targetmol</p>
                            </a>
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
              <div className={classes.swiperInnerDiv}>
                <img src={TargetMol2Img} alt="Abcam promotion informations" className={classes.carousel__image} />
              </div>
              <div className={classes.swiperInnerDivMobile}>
                <img src={TargetMol2ImgMobile} alt="Abcam promotion informations" className={classes.carousel__image} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.swiperInnerDiv}>
                <img src={TargetMol3Img} alt="Abcam promotion informations" className={classes.carousel__image} />
              </div>
              <div className={classes.swiperInnerDivMobile}>
                <img src={TargetMol3ImgMobile} alt="Abcam promotion informations" className={classes.carousel__image} />
              </div>
            </SwiperSlide>  */}
                </Swiper>
            </div>
            {/* <div className={classes.bottom__divider}></div> */}
        </div>
    </section>);
};
export default Carousel;
